import { useCallback } from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Radio,
  RadioGroup,
  Stack,
  VStack,
} from '@chakra-ui/react';
import { useHashParamJson } from '@metapages/hash-query';

import { ButtonClearCache } from './components/ButtonClearCache';
import { ChannelField } from './components/ChannelField';
import { MenuJsonUrlField } from './components/MenuJsonUrlField';
import { RadioButtonMode } from './components/RadioButtonMode';

export type PersistMode = "localStorage" | "hashParams";

export type Options = {
  someStringOption?: string;
  showControlDebug?: boolean;
  hideProjectorURL?: boolean;
  deviceIOViaMetapage?: boolean;
  persistMode?: PersistMode;
};

const defaultOptions: Options = {
  someStringOption: "foo",
  showControlDebug: undefined,
  hideProjectorURL: undefined,
  deviceIOViaMetapage: undefined,
  persistMode: "localStorage",
};

const OptionDescription: Record<string, string> = {
  someStringOption: "foo",
  showControlDebug: "Show control debug panel in main tab",
  hideProjectorURL: "Hide projector URL in main tab",
  deviceIOViaMetapage: "DeviceIO via metapages",
};

const validationSchema = yup.object({
  someStringOption: yup.string().optional(),
  showControlDebug: yup.boolean().optional(),
  hideProjectorURL: yup.boolean().optional(),
  deviceIOViaMetapage: yup.boolean().optional(),
  persistMode: yup
    .string()
    .default("localStorage")
    .required()
    .oneOf(["localStorage", "hashParams"]),
});
interface FormType extends yup.InferType<typeof validationSchema> {}

export const PanelOptions: React.FC = () => {
  const [options, setOptions] = useHashParamJson<Options>(
    "options",
    defaultOptions
  );

  const onSubmit = useCallback(
    (values: FormType, other: any) => {
      console.log("values", values);
      setOptions(values as Options);
    },
    [setOptions]
  );

  const formik = useFormik({
    initialValues: {
      showControlDebug: options.showControlDebug,
      hideProjectorURL: options.hideProjectorURL,
      someStringOption: options.someStringOption,
      deviceIOViaMetapage: options.deviceIOViaMetapage,
      persistMode: options?.persistMode || "localStorage",
    },
    onSubmit,
    validationSchema,
  });

  return (
    <VStack
      maxW="700px"
      gap="1rem"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      <ChannelField />
      <MenuJsonUrlField />
      <RadioButtonMode />

      <form onSubmit={formik.handleSubmit}>
        <FormControl pb="1rem">
          <FormLabel fontWeight="bold">
            Persist changes to the menu configuration to:
          </FormLabel>
          <RadioGroup
            id="persistMode"
            onChange={(e) => {
              // currently RadioGroup needs this to work
              formik.setFieldValue("persistMode", e);
              formik.handleSubmit();
            }}
            value={formik.values.persistMode}
          >
            <Stack
              pl="30px"
              pr="30px"
              spacing={5}
              direction="column"
              borderWidth="1px"
              borderRadius="lg"
            >
              <Radio value="localStorage" defaultChecked>
                Local browser storage (default), this computer only
              </Radio>
              <Radio value="hashParams">URL hash parameters (after the `#?`): share or save URL</Radio>
            </Stack>
          </RadioGroup>
          
        </FormControl>

        <FormControl pb="1rem">
          <FormLabel fontWeight="bold" htmlFor="someStringOption">
            Some string option (saved in hash params)
          </FormLabel>
          <InputGroup>
            <Input
              name="someStringOption"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.someStringOption}
            />
          </InputGroup>
        </FormControl>

        {Object.keys(validationSchema.fields as any)
          .filter(
            (fieldName) =>
              (validationSchema.fields as any)[fieldName].type === "boolean"
          )
          .map((fieldName) => (
            <FormControl pb="1rem" key={fieldName}>
              <FormLabel fontWeight="bold" htmlFor={fieldName}>
                {OptionDescription[fieldName]}
              </FormLabel>
              <Checkbox
                name={fieldName}
                size="lg"
                bg="gray.100"
                spacing="1rem"
                onChange={(e) => {
                  // currently checkbox needs this to work
                  formik.setFieldValue(fieldName, e.target.checked);
                  formik.handleSubmit();
                }}
                isChecked={(formik.values as any)[fieldName]}
              />
            </FormControl>
          ))}

        <Button type="submit" display="none">
          submit
        </Button>
      </form>

      <FormLabel fontWeight="bold">
        Clear local storage of config and state{" "}
      </FormLabel>
      <ButtonClearCache />
      <Box pb="1rem" />
    </VStack>
  );
};
