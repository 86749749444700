import '/@/app.css';

import { PanelHelp } from '/@/components/PanelHelp';
import { PanelMain } from '/@/components/PanelMain';
import { FiSettings } from 'react-icons/fi';

import {
  EditIcon,
  InfoIcon,
  ViewIcon,
} from '@chakra-ui/icons';
import {
  HStack,
  Show,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from '@chakra-ui/react';
import {
  useHashParam,
  useHashParamBoolean,
  useHashParamInt,
} from '@metapages/hash-query';

import {
  useMetaframeSignals,
} from './components/communication/useMetaframeSignals';
import { PanelNotion } from './components/notion/PanelNotion';
import {
  ButtonTabsToggle,
} from './components/options/components/ButtonTabsToggle';
import { PanelOptions } from './components/options/PanelOptions';
import { PanelJsonEditor } from './components/PanelJsonEditor';

export const App: React.FC = () => {
  const [hideMenu] = useHashParamBoolean("menuhidden");
  const [mode] = useHashParam("button", undefined);
  const [tab, setTab] = useHashParamInt("tab");
  // Wire up the metaframe signals, other components can listen more conveniently
  useMetaframeSignals();

  if (hideMenu) {
    if (mode === undefined || mode === "visible" || mode === "invisible") {
      return (
        <>
          <HStack
            style={{ position: "absolute" }}
            width="100%"
            justifyContent="flex-end"
          >
            <Spacer />
            <Show breakpoint="(min-width: 200px)">
              <ButtonTabsToggle />
            </Show>
          </HStack>
          <PanelMain />

        </>
      );
    } else if (mode === "hidden") {
      return <PanelMain />;
    }
  }
  return (
    <VStack align="flex-start" w="100%">
    <Tabs index={tab || 0} isLazy={true} onChange={setTab} w="100%">
      <TabList>
        <Tab>
          <ViewIcon /> &nbsp; Superslides
        </Tab>
        <Tab>
          <EditIcon /> &nbsp; Notion
        </Tab>
        <Tab>
          <FiSettings /> &nbsp; Options
        </Tab>
        <Tab>
          <EditIcon /> &nbsp; Configuration Editor
        </Tab>
        <Tab>
          <InfoIcon />
          &nbsp; Help
        </Tab>
        <Spacer /> <ButtonTabsToggle />
      </TabList>

      <TabPanels>
        <TabPanel>
          <PanelMain />
        </TabPanel>
        <TabPanel>
          <PanelNotion />
        </TabPanel>
        <TabPanel>
          <PanelOptions />
        </TabPanel>
        <TabPanel>
          <PanelJsonEditor />
        </TabPanel>
        <TabPanel>
          <PanelHelp />
        </TabPanel>
      </TabPanels>
    </Tabs>
    </VStack>
  );
};
