import { useStore } from '/@/store';

import {
  Box,
  VStack,
} from '@chakra-ui/react';

import { PanelHandOs } from './hand-os/PanelHandOs';
import { useSuperslidesConfig } from './hand-os/useSuperslidesConfig';

/**
 * Just an example very basic output of incoming inputs
 *
 */
export const PanelMain: React.FC = () => {
  
  // this goes here since we need to load all the menu
  // to maybe show something different if there is none
  useSuperslidesConfig();
  
  // useSuperslidesConfig will put the menu in the store
  const menu = useStore((state) => state.menu);

  return (
    <VStack align="flex-start">
      {/* <DeviceIO /> */}
      {menu ? <PanelHandOs /> : <Box>...</Box>}
    </VStack>
  );
};
