import {
  useCallback,
  useState,
} from 'react';

import { MenuConfig } from '/@/shared/menu';
import { useStore } from '/@/store';
import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  Text,
} from '@chakra-ui/react';
import { useHashParamInt } from '@metapages/hash-query';

import { useSuperslidesConfig } from '../hand-os/useSuperslidesConfig';

const URL_NOTION_TO_MARKDOWN = import.meta.env.VITE_URL_NOTION_TO_MARKDOWN || "/notion-to-markdown";

export type Options = {
  notionPageId: string;
  notionApiKey: string;
};

export const defaultOptions: Options = {
  notionPageId: "",
  notionApiKey: "",
};

const validationSchema = yup.object({
  notionPageId: yup.string().required(),
  notionApiKey: yup.string().required(),
});
interface FormType extends yup.InferType<typeof validationSchema> {}

export const PanelNotion: React.FC = () => {
  const {setMenuConfig} = useSuperslidesConfig();
  // const setMenuConfig = useStore((state) => state.setMenuConfig);
  const setMenuState = useStore((state) => state.setMenuState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [_, setTab] = useHashParamInt("tab");

  const onSubmit = useCallback(
    async (values: FormType) => {
      setIsLoading(true);
      setIsSuccess(false);
      try {
        let maybePageIdOrUrl: string = values.notionPageId;
        if (maybePageIdOrUrl.startsWith("http")) {
          const url = new URL(maybePageIdOrUrl);
          maybePageIdOrUrl = url.pathname.split("/").pop() || "";
          maybePageIdOrUrl = maybePageIdOrUrl.split("-").pop() || "";
        }

        const body = JSON.stringify({
          pageId: maybePageIdOrUrl,
          token: values.notionApiKey,
        });

        const resp = await fetch(URL_NOTION_TO_MARKDOWN, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow",
          mode: "cors",
          body,
        });
        const json: { menu: MenuConfig } = await resp.json();
        setMenuConfig(json.menu);
        setIsLoading(false);
        setIsSuccess(true);
        setTab(0);

      } catch (e) {
        setIsLoading(false);
        console.error(e);
      }
    },
    [setMenuConfig, setMenuState, setIsLoading, setIsSuccess, setTab]
  );

  const formik = useFormik({
    initialValues: {
      notionPageId: "",
      notionApiKey: "",
    },
    onSubmit,

    validationSchema,
  });

  return (
    <>
      <br />
      <form onSubmit={formik.handleSubmit}>
        <FormControl>
          <FormLabel fontWeight="bold" htmlFor="notionPageId">
            Notion database Id
          </FormLabel>

          <InputGroup pb="25px">
            <Input
              maxW="600px"
              bg="gray.100"
              autoComplete="username"
              id="notionPageId"
              name="notionPageId"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.notionPageId}
            />
          </InputGroup>

          <FormLabel fontWeight="bold" htmlFor="notionApiKey">
            Notion API key
          </FormLabel>

          <InputGroup pb="25px">
            <Input
              maxW="600px"
              bg="gray.100"
              autoComplete="new-password"
              id="notionApiKey"
              name="notionApiKey"
              type="password"
              onChange={formik.handleChange}
              value={formik.values.notionApiKey}
            />
          </InputGroup>
        </FormControl>

        <HStack>
          <Button
            colorScheme="blue"
            type="submit"
            isLoading={isLoading}
            loadingText="converting"
          >
            Refresh Superslides
          </Button>
          <Text>{isSuccess ? "✅" : ""}</Text>
        </HStack>
      </form>
    </>
  );
};
