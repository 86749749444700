import { useCallback } from 'react';

import { MetaframeInputMap } from '@metapages/metapage';
import { MetaframeStandaloneComponent } from '@metapages/metapage-embed-react';

import { useSuperslidesConfig } from './hand-os/useSuperslidesConfig';

export const PanelJsonEditor: React.FC = () => {

  const { menuConfig, setMenuConfig} = useSuperslidesConfig();
  // const menuConfig = useStore((state) => state.menu);
  // const setMenuConfig = useStore((state) => state.setMenuConfig);
  
    
  const onOutputs = useCallback(
    (outputs: MetaframeInputMap) => {
      if (outputs["value"] === undefined || outputs["value"] === null) {
        return;
      }
      setMenuConfig(outputs["value"]);
    },
    [setMenuConfig]
  );

  return (
    <div>
      <MetaframeStandaloneComponent
        url="https://editor.mtfm.io/#?options=eyJhdXRvc2VuZCI6ZmFsc2UsImhpZGVtZW51aWZpZnJhbWUiOnRydWUsIm1vZGUiOiJqc29uIiwidGhlbWUiOiJsaWdodCJ9"
        inputs={{ value: menuConfig}}
        onOutputs={onOutputs}
      />
    </div>
  );
};