// These data structures represent:
//   - a cursor position, starting at the first menu
//   - the cursor position can be moved "into" a menu item
//   - to move "out of" a menu item, back into the menu, is defined
//     by the menu itself, e.g. move up to get out of the menu item

export type MenuItemId = string;
export type MenuId = string;

// Default: if no selectedIndex, start at 0
export type MenuType = "Default";
export const MenuTypes: Record<MenuType, MenuType> = {
  Default: "Default"
};

export type MenuItemState = any;

export type MenuState = {
  selectedIndex: number;
  menuItemStates?: MenuItemState[];
}

export type MenuControllerType = "Default";
export type MenuController = {
    type: MenuControllerType;
    definition?:any
}
    
export interface Menu {
  id: MenuId;
  definition: {
      type?: MenuType;
      name?: string;
      // i imagine there can be different ways to control the menu
      controller?: MenuController;
      // A remote superslides projector
      slideProjectorUrl?: string;
  }
  // the core: the menu items
  items: MenuItemId[];
}

export type MenuItemActionType = "menu" | "url" | "metapage";
export const MenuItemTypes: Record<MenuItemActionType, MenuItemActionType> = {
  menu: "menu",
  url: "url",
  metapage: "metapage",
};

export type MenuItemActionMenu = {
  url?: string;
  // go to this menu
  menu: string;
  // maybe go to this menu item
  menuItemIndex?: number;
};
export type MenuItemActionUrl = {
  url: string;
};
export type MenuItemActionMetapage = {
  // This is a definition of a metapage (not a URL)
  // I cannot type it here because it's shared between deno and
  // browser typescript
  metapage: any;
};

export interface MenuItemDefinition {
  id: MenuItemId;
  name?: string;

  type: MenuItemActionType;
  value?: |MenuItemActionMenu | MenuItemActionUrl | MenuItemActionMetapage ;

  // url: string;
  // wss: string;
}

export interface MenuConfig {
  menus: Menu[];
  // menuItems: { [key: string]: MenuItemDefinition };
  // menuItems: Map<string, MenuItemDefinition>;
  menuItems: Record<string, MenuItemDefinition>;
}

export type MenuConfigState = { 
  currentMenuId : MenuId;
  menus: Record<MenuId, MenuState>;
};
