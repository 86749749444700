import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  Menu,
  MenuItemActionMenu,
  MenuItemActionUrl,
  MenuItemDefinition,
  MenuItemTypes,
} from '/@/shared/menu';
import { useStore } from '/@/store';

import {
  Box,
  Button,
  FormLabel,
  HStack,
  Link,
  VStack,
} from '@chakra-ui/react';
import { useHashParamJson } from '@metapages/hash-query';

import {
  AccelerometerButtons,
} from '../control-mechanisms/AccelerometerButtons';
import {
  BackHaptic,
  CannotGoForwardHaptic,
  CannotGoLeftHaptic,
  CannotGoRightHaptic,
  ForwardHaptic,
  LeftHaptic,
  RightHaptic,
} from '../control-mechanisms/haptics/HapticLibrary';
import { Options } from '../options/PanelOptions';
import { SlidesProjectorUrl } from './constants';
import { DeviceIO } from './DeviceIO';
import {
  MenuModelCursor,
  TapDirection,
} from './MenuModel';
import { useSuperslidesConfig } from './useSuperslidesConfig';

/**
 * The main panel for the HandOS
 *
 */
export const PanelHandOs: React.FC = () => {
  const {menuModel:superslides} = useSuperslidesConfig();
  const [menu, setMenu] = useState<Menu | undefined>(superslides?.current);
  const [menus, setMenus] = useState<Menu[]>([]);
  const [menuItem, setMenuItem] = useState<MenuItemDefinition | undefined>();
  const [menuItems, setMenuItems] = useState<MenuItemDefinition[]>([]);
  const [iframeUrl, setIframeUrl] = useState<string | undefined>();
  const deviceIO = useStore((state) => state.deviceIO);

  useEffect(() => {
    if (!superslides || !superslides.config.menus) {
      setMenus([]);
      return;
    }
    setMenus(superslides.config.menus);
  }, [superslides]);

  const onMenuItemSelect = useCallback(
    (index: number) => {
      if (!superslides) {
        return;
      }
      const cursor = superslides.setMenuItemSelection(index);
      setMenuItem(cursor.item);
      if (cursor.menu) {
        setMenu(cursor.menu);
      }
    },
    [menu, setMenuItem, superslides]
  );

  const onMenuDirection = useCallback(
    (direction: TapDirection | undefined) => {
      if (!direction || !deviceIO || !superslides) {
        return;
      }
      let cursor: MenuModelCursor | undefined;

      if (direction === "left") {
        cursor = superslides.onMenuLeft();
        if (cursor.previous) {
          deviceIO.haptics.dispatch(LeftHaptic);
          // sendHaptic(LeftHaptic);
        } else {
          deviceIO.haptics.dispatch(CannotGoLeftHaptic);
          // sendHaptic(CannotGoLeftHaptic);
        }
        setMenuItem(cursor.item);
        if (cursor.menu) {
          setMenu(cursor.menu);
        }
      } else if (direction === "right") {
        cursor = superslides.onMenuRight();
        if (cursor.previous) {
          deviceIO.haptics.dispatch(RightHaptic);
        } else {
          deviceIO.haptics.dispatch(CannotGoRightHaptic);
        }
        setMenuItem(cursor.item);
        if (cursor.menu) {
          setMenu(cursor.menu);
        }
      } else if (direction === "forward") {
        cursor = superslides.onMenuForward();
        if (
          cursor.previous?.item?.type === MenuItemTypes.menu ||
          cursor.previous?.item?.type === MenuItemTypes.metapage
        ) {
          deviceIO.haptics.dispatch(ForwardHaptic);
        } else {
          deviceIO.haptics.dispatch(CannotGoForwardHaptic);
        }

        setMenuItem(cursor.item);
        if (cursor.menu) {
          setMenu(cursor.menu);
        }
      } else if (direction === "back") {
        cursor = superslides.onMenuBack();
        if (cursor.previous && cursor.previous?.menu !== cursor.menu) {
          deviceIO.haptics.dispatch(BackHaptic);
          setMenu(cursor.menu);
        } else {
          deviceIO.haptics.dispatch(CannotGoForwardHaptic);
        }
      }
    },
    [setMenuItem, superslides, deviceIO]
  );

  const controller = menu ? (
    <AccelerometerButtons onDirection={onMenuDirection} />
  ) : null;

  const onMenuSelect = useCallback(
    (menu: Menu) => {
      if (!superslides) {
        return;
      }
      const { menu: newMenu, item } = superslides.setMenu(menu.id);
      setMenu(newMenu);
      setMenuItem(item);
    },
    [menu, setMenu, superslides]
  );

  const onMenuClick = useCallback(() => {
    onMenuItemSelect(-1);
  }, [onMenuItemSelect]);

  const onMenuItemClick = useCallback(() => {
    if (!menuItem || !superslides) {
      return;
    }
    if (menuItem.type === MenuItemTypes.menu) {
      const blob = menuItem.value as MenuItemActionMenu;
      const change: MenuModelCursor = superslides.setMenu(blob.menu);
      if (change.menu) {
        setMenu(change.menu);
      }
    }
  }, [menuItem, superslides, setMenu]);

  // On new Menu, update the menuItems, and selected
  useEffect(() => {
    if (!superslides) {
      setMenuItems([]);
      setMenuItem(undefined);
      return;
    }
    setMenuItems(
      superslides.current.items.map((itemId) => superslides.menuItems[itemId])
    );
    setMenuItem(superslides.getMenuItemSelected());
  }, [menu, setMenuItems, setMenuItem, superslides]);

  // On new MenuItem
  useEffect(() => {
    let url: string | undefined = undefined;
    if (menuItem?.type === MenuItemTypes.url) {
      const blob = menuItem.value as MenuItemActionUrl;
      url = blob.url;
    }
    if (menuItem?.type === MenuItemTypes.menu) {
      const blob = menuItem.value as MenuItemActionMenu;
      url = blob.url;
    }

    if (menu?.definition?.slideProjectorUrl) {
      url = `${SlidesProjectorUrl}/#?channel=${new URL(
        menu.definition.slideProjectorUrl
      ).pathname.replace("/", "")}`;
    }

    setIframeUrl(url);
  }, [menuItem, menu]);

  // On new Superslides, update the menu
  useEffect(() => {
    if (!superslides) {
      setMenu(undefined);
      return;
    }
    setMenu(superslides.root);
  }, [superslides, setMenu]);

  const [options] = useHashParamJson<Options>("options");

  return (
    <HStack align="flex-start" w="100%">
      <VStack align="flex-start">
        <FormLabel textColor="gray.500">Menus:</FormLabel>
        <VStack
          borderWidth="1px"
          borderRadius="lg"
          p="10px"
          w="300px"
          align="flex-start"
          justifyContent="space-between"
        >
          <VStack align="flex-start" w="100%">
            {menus.map((m, index) => (
              <VStack
                align="flex-start"
                w="100%"
                key={index}
                borderColor={m.id === menu?.id ? "black" : undefined}
                borderWidth="1px"
                borderRadius="lg"
                textAlign="left"
              >
                <Button
                  onClick={
                    m === menu ? () => onMenuClick : () => onMenuSelect(m)
                  }
                  bg={m.id === menu?.id ? "gray.200" : "white"}
                  justifyContent="flex-start"
                  w="100%"
                >
                  {m.definition.name || m.id}
                </Button>
              </VStack>
            ))}
          </VStack>
          <Box p={3} m={0}>
            <DeviceIO />
          </Box>
        </VStack>
      </VStack>

      <VStack h="100%" align="flex-start" textAlign="center" w="100%">
        <FormLabel textAlign="center" textColor="gray.500">
          Slides:{" "}
        </FormLabel>
        <VStack align="flex-start" w="100%">
          {controller}

          <HStack
            w="100%"
            p="10px"
            align="flex-start"
            borderWidth="1px"
            borderRadius="lg"
          >
            {menuItems.map((item, index) => (
              <Box
                borderWidth="1px"
                borderRadius="lg"
                borderColor={menuItem?.id === item.id ? "black" : undefined}
                key={index}
              >
                <Button
                  onClick={() => onMenuItemSelect(index)}
                  bg={menuItem?.id === item.id ? "gray.200" : "white"}
                  justifyContent="flex-start"
                  w="100%"
                >
                  {item.name || item.id}
                </Button>
              </Box>
            ))}
          </HStack>

          {options?.hideProjectorURL ? null : (
            <HStack
              align="flex-start"
              justifyContent="flex-end"
              w="100%"
              alignSelf="flex-end"
            >
              <FormLabel textColor="gray.500">Projector URL: </FormLabel>

              {menu?.definition.slideProjectorUrl ? (
                <Link
                  isExternal
                  href={`${SlidesProjectorUrl}/#?channel=${new URL(
                    menu.definition.slideProjectorUrl
                  ).pathname.replace("/", "")}`}
                >{`${SlidesProjectorUrl}/#?channel=${new URL(
                  menu.definition.slideProjectorUrl
                ).pathname.replace("/", "")}`}</Link>
              ) : null}
            </HStack>
          )}

          <Box
            borderWidth="1px"
            borderRadius="lg"
            bg="white"
            w="100%"
            h="500px"
            onClick={onMenuItemClick}
            className="iframe-container"
          >
            {iframeUrl ? <iframe className="iframe" src={iframeUrl} /> : null}
          </Box>
        </VStack>
      </VStack>
    </HStack>
  );
};
