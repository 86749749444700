import { useCallback } from 'react';

import { useStore } from '/@/store';
import { FiTrash2 } from 'react-icons/fi';

import { Button } from '@chakra-ui/react';

export const ButtonClearCache: React.FC = () => {
  const setMenuConfig = useStore((state) => state.setMenuConfig);
  const setMenuState = useStore((state) => state.setMenuState);
  const menu = useStore((state) => state.menu);
  

  const onClick = useCallback(() => {
    setMenuConfig(null, "localStorage");
    setMenuState(null);
  }, [setMenuConfig, setMenuState]);

  return (
    <Button
      aria-label="clear"
      maxW="200px"
      onClick={onClick}
      disabled={!menu}
      leftIcon={<FiTrash2 />}
    >{menu === null ? "Nothing saved locally" : "Clear local cache"}</Button>
  );
};
